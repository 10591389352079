<template>
    <tr>
        <td>{{indexMember}}</td>
        <td>{{clan.name}}</td>
        <td>{{member.name}}</td>
        <td>{{renderPosition(member.position)}}</td>
        <td>{{member.nickname}}</td>
        <td>{{member.hp}}</td>
      <td v-if="clan.approve_status === 1">
            <button v-if="accept" class="btn btn-success btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="detail"
                    aria-controls="dt-basic-example" type="button" title="Xem chi tiết"><span><i
                class="fal fa-eye mr-1 "></i> Xem chi tiết</span>
            </button>
            <!-- <button v-if="accept" class="btn btn-warning btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="gift"
                    aria-controls="dt-basic-example" type="button" title="Thưởng"><span><i
                class="fal fa-gift mr-1"></i> Thưởng</span>
            </button> -->
            <button v-if="accept" class="btn btn-info btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="punish"
                    aria-controls="dt-basic-example" type="button" title="Phạt"><span><i
                class="fal fa-battery-quarter mr-1"></i> Phạt</span>
            </button>
            <button v-if="accept" class="btn btn-default btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="outTeam"
                    aria-controls="dt-basic-example" type="button" title="Rời team"><span><i
                class="fal fa-sign-out mr-1"></i> Rời team</span>
            </button>
        </td>
      <td v-else>
      </td>
    </tr>

</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import Swal from "sweetalert2";
import {GIFT} from "@/core/services/store/service.module";
import {LEAVE_TEAM} from "@/core/services/store/user/teacher.module";
import {USER_CLAN_POSITION} from "@/core/config/commonConfig";

const _ = deepdash(lodash);

export default {
    components: {},
    name: 'row-member',
    props: {
        accept: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'lesson'
        },
        clan: {
            type: Object,
            default() {
              return {}
            }
        },
        member: {
          type: Object,
          default() {
           return {}
          }
        },
        indexMember: {
          type: Number,
          default: 1
        }
    },
    data() {
        return {
          user: {
            id: '',
            name : '',
          },
          clan_id : '',
          USER_CLAN_POSITION
        }
    },
    methods: {
        detail() {
            this.$emit('detail', { id: this.member.id, name: this.member.name, course_student_id: this.member.course_student_id, clan: this.clan })
        },

        async sendGift() {
            let params = this.customParams();
            params = _.merge(params, {user_clan_id: this.member.id})
            this.$store.dispatch(GIFT, params)
                .then(() => {
                    this.$message({
                      type: 'success',
                      message: 'Tặng quà thành công!',
                      showClose: true
                    });
                    this.$emit('gift', { id: this.member.student_id, name: this.member.name });
                    this.loading.save = false;
                }).catch((response) => {
                  this.$notify({
                    title: 'Warning',
                    message: `Serve đang bận, vui lòng thử lại sau: ${response.data.data.message}`,
                    type: 'warning'
                  });
            });
        },

        customParams() {
            let params = { };
            if (this.member.id) {
                params = _.merge(params, { user_id: this.member.student_id })
            }
            return params
        },

        gift() {
            Swal.fire({
                title: `Bạn chắc chắn muốn thưởng cho ${this.member.name} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1dc9b7',
                cancelButtonColor: '#E5E5E5',
                confirmButtonText: 'Tôi đồng ý',
                cancelButtonText: 'Đóng'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sendGift()
                }
            })
        },
        punish() {
            this.$emit('punish', { id: this.member.id, name: this.member.name })
        },
        outTeam() {
            Swal.fire({
                title: `Bạn chắc chắn muốn ${this.member.name} rời khỏi Team?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7453a6',
                cancelButtonColor: '#E5E5E5',
                confirmButtonText: 'Tôi đồng ý',
                cancelButtonText: 'Đóng'
            }).then((result) => {
                if (result.isConfirmed) {
                  this.$store.dispatch(LEAVE_TEAM, {user_clan_id : this.member.id}).then(()=> {
                    this.$emit('reloadPage');
                    Swal.fire({
                      title: 'Rời team thành công',
                      text: `Đã cho ${this.member.name} rời khỏi Team`,
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#7453a6',
                      confirmButtonText: 'Đóng',
                    });
                  }).catch(() => {
                    Swal.fire({
                      title: 'Có lỗi xảy ra!',
                      text: `Sever đang bận! Vui lòng thử lại sau`,
                      icon: 'danger',
                      showCancelButton: false,
                      confirmButtonColor: '#7453a6',
                      confirmButtonText: 'Đóng',
                    })
                  });

                }
            })
        },

        renderPosition(position){
          return  this.USER_CLAN_POSITION.reduce((result, item) => {
             if (item.key === position){
               result =  item.value;
             }
             return result;
          }, '');
        }
    },

}
</script>
