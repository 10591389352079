<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
            <div class="form-row">
                <div class="col-md-12 mb-3">
                    <label class="form-label">Nhập lý do từ chối <span class="text-danger">(*)</span></label>
                    <validation-provider name="Lý do" rules="required" vid="reason" v-slot="{ errors, classes}">
                      <input type="text" class="form-control" name="reason" id="reason" ref="reason" placeholder="Nhập Thông tin bị từ chối"
                           autocomplete="false" v-model="form.reason_remove" :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="reason" data-validator="notEmpty"
                             class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </validation-provider>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button class="btn btn-default mr-2" type="button" @click="close">
                    <i v-if="loading" class="el-icon-loading"></i> Đóng
                </button>
                <button class="btn btn-primary" type="submit">
                    <i v-if="loading" class="el-icon-loading"></i> Xác nhận
                </button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import { APPROVE_TEAM } from "@/core/services/store/user/teacher.module";

const _ = deepdash(lodash);

export default {
    components: {},
    props: {
        // id của team
        idTeam : {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            loading: false,
            form: {
              reason_remove: '',
            }
        }
    },
    methods: {
        summit() {
          let payload = {
            status : 0,
            reason_remove : this.form.reason_remove,
            clan_id : this.idTeam,
          };
          this.$store.dispatch(APPROVE_TEAM, payload).then(() =>{
            this.$emit('close');
            this.$message({
              type: 'warning',
              message: 'Đã từ chối Team!',
              showClose: true
            });
            this.$emit("reloadPage");
          }).catch((res) => {
            this.$emit('close');
            this.$message({
              type: 'warning',
              message: 'Có lỗi xảy ra!',
              showClose: true
            });
          });
        },
        close: function () {
            this.$emit('close');
        },
    },
}
</script>
