<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
            <div class="form-row mt-4">
                <div class="col-md-3 mb-3">
                    <label class="form-label">Mã học viên</label>
                    <input type="text" class="form-control" :disabled="true" placeholder="Mã học viên"
                           autocomplete="false" :value="form.code">
                </div>
                <div class="col-md-3 mb-3">
                    <label class="form-label">Họ và tên</label>
                    <input type="text" class="form-control" :disabled="true" placeholder="Họ tên học viên"
                           autocomplete="false" :value="form.name">
                </div>
                <div class="col-md-3 mb-3">
                    <label class="form-label">Email</label>
                    <input type="text" class="form-control" :disabled="true" placeholder="Email của học viên"
                           autocomplete="false" v-model="form.email">
                </div>
                <div class="col-md-3 mb-3">
                    <label class="form-label">Số điện thoại</label>
                    <input type="text" class="form-control" :disabled="true" placeholder="Số điện thoại"
                           autocomplete="false" v-model="form.phone">
                </div>
                <div class="col-md-3 mb-3">
                    <label class="form-label">Giới tính</label>
                    <edutalk-select disabled id="gender" v-model="form.gender" class="w-100"
                                    placeholder="Chọn giới tính">
                        <edutalk-option
                            v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name">
                        </edutalk-option>
                    </edutalk-select>
                </div>
                <div class="col-md-3 mb-3">
                    <label class="form-label">Ngày sinh</label>
                    <el-date-picker id="birthDay"
                                    format="dd-MM-yyyy"
                                    ref="birthday"
                                    name="birthday"
                                    disabled
                                    value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                    v-mask="'##-##-####'" v-model="form.birthday"
                                    default-value="2000-10-10"
                                    style="width: 100%;"
                    ></el-date-picker>
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Địa chỉ</label>
                    <input type="text" class="form-control" :disabled="true" placeholder="Địa chỉ của học viên"
                           autocomplete="false" v-model="form.address">
                </div>
                <el-divider content-position="left">Thông tin nhân vật</el-divider>
                <div class="col-md-3 mb-3">
                    <h6>Nhân vật: <b>{{ form.character_name }}</b></h6>
                    <h6>Level: <b class="text-info">{{ form.level }}</b></h6>
                    <h6>Vàng: <b class="text-warning">{{ form.coin }}</b></h6>
                    <h6>HP: <b><span :class="{'text-danger': form.HP < 50, 'text-success': form.HP > 50 }">{{ form.HP }}</span>/<span
                        class="text-success">{{ form.total_hp }}</span></b></h6>
                    <h6>Chức danh: <b>{{ form.position }}</b></h6>
                    <h6>Huy hiệu: <b> <a href="#" @click="showAllBlades">Xem tất cả</a></b> </h6>
                        <CoolLightBox
                            :items="itemImages"
                            :index="index"
                            :effect="'fade'"
                            @close="index = null">
                        </CoolLightBox>
                      <div class="images-wrapper">
                        <div
                            class="image"
                            v-for="(image, imageIndex) in itemImages"
                            :key="imageIndex"
                            @click="index = imageIndex"
                            :style="{ backgroundImage: 'url(' + image + ')' }"
                        ></div>
                      </div>
                </div>
                <div class="col-md-9 mb-3">
                    <el-tabs type="border-card">
                        <el-tab-pane>
                            <span slot="label"><i class="el-icon-date"></i> Từ vựng</span>
                          <h6>Tổng từ vựng: <b><span class="text-success">{{ tong_tu }}</span></b></h6>
                          <h6>Từ vựng kém: <b><span class="text-danger">{{ tu_vung_kem }}</span></b></h6>
                          <zingchart ref="chart" :data="chartVocabData" :theme="zingChartTheme"></zingchart>
                        </el-tab-pane>
                        <el-tab-pane label="Ngữ pháp">
                            <span slot="label"><i class="el-icon-cloudy"></i> Ngữ pháp</span>
                            <div class="row box-item-score">
                              <div class="col-md-4 pr-0">
                                <h6 class="txt-custom">Điểm trung bình: <b><span class="text-success">{{ diem_trung_binh.toFixed(2) }}</span></b></h6>
                              </div>
                              <div class="col-md-4 pr-0">
                                <h6 class="txt-custom pr-0">Điểm kiểm tra giữa kì: <b><span class="text-success">{{ diem_giua_ki.toFixed(2) }}</span></b></h6>
                              </div>
                              <div class="col-md-4 pr-0">
                                <h6 class="txt-custom">Điểm kiểm tra cuối kì: <b><span class="text-success">{{ diem_cuoi_ki.toFixed(2) }}</span></b></h6>
                              </div>
<!--                              <div class="col-md-3">-->
<!--                                <h6>Kết quả năng lực đầu vào: <b><span class="text-success">0</span></b></h6>-->
<!--                              </div>-->
                            </div>
                          <zingchart ref="chart" :data="chartGrammarData" :theme="zingChartTheme"></zingchart>
                        </el-tab-pane>
                        <el-tab-pane label="Chuyên cần">
                            <span slot="label"><i class="el-icon-aim"></i> Chuyên cần</span>
                          <h6>Số bài tập đã làm: <b><span class="text-success">{{ total_homework }}</span></b></h6>
                          <h6>Số buổi học: <b><span class="text-danger">{{ total_attendance }}</span></b></h6>
                          <zingchart ref="chart" :data="chartAttendanceData" :theme="zingChartTheme"></zingchart>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button class="btn btn-default mr-2" type="button" @click="close">
                    <i v-if="loading" class="el-icon-loading"></i> Đóng
                </button>
            </div>
        </form>
      <el-dialog :title="dialog.title" class="responsive w-30 mx-auto my-auto" :visible.sync="dialogAllBadges" append-to-body :lock-scroll="false" :center="true" :show-close="true">
        <all-badges @close="closeDialog()" :items="dialog.images" :item-images="allBadges"></all-badges>
      </el-dialog>
    </ValidationObserver>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {CHI_TIET_USER, ALL_BADGES, STUDENT_RESULT_VOCAB, STUDENT_RESULT_GRAMMAR, DETAIL_ATTENDANCE} from "@/core/services/store/user/teacher.module";
import AllBadges from './all-badges';
const _ = deepdash(lodash);
import {zingChartTheme} from "@/core/config/zingChartTheme";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import '@/assets/sass/custom/cool_light_box.scss';

export default {
    components: {
        AllBadges,
        CoolLightBox
    },
    name: 'detail-member',
    props: {
        // id của team
        idMember: {
            type: Number,
            default(){
             return 0;
            }
        },
      course_student_id: {
            type: Number,
            default(){
             return 0;
            }
        },
      clan: {
            type: Object,
            default(){
             return {};
            }
        },
    },
    data() {
        return {
            zingChartTheme: zingChartTheme,
            loading: false,
            form: {
                id: '',
                name: '',
                email: '',
                phone: '',
                gender: '',
                birthday: '',
                code: '',
                character_name: '',
                position: '',
                level: '',
                coin: '',
                HP: '',
                total_hp: '',
            },
            character: {
                name: 'Songoku',
                level: 21,
                gold: 100,
                hp: 100,
                totalHP: 100,
                chuc_danh: 'Chủ tướng',
                huy_hieu: []
            },
            index: null,
            publicPath: process.env.VUE_APP_BASE_URL + 'media/badged/',
            itemImages: [],
            allBadges: [],
            dialogAllBadges: false,
            dialog: {
              title: null,
              images: [],
            },
            tong_tu : 0,
            tu_vung_kem: 0,
            total_attendance: 0,
            total_homework: 0,
            diem_trung_binh: 0,
            diem_giua_ki: 0,
            diem_cuoi_ki: 0,
            timeLearn: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
            labelSaleX: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
            labelSaleGramaX: [0, 0, 0, 0, 0],
            labelSaleVocabX: [0, 0, 0, 0, 0],
            so_cau: [0, 0, 0, 0, 0],
            So_buoi: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
            so_diem: [0, 0, 0, 0, 0],
            tong_so_diem: [0, 0, 0, 0, 0],
            so_diem_vocab: [0, 0, 0, 0, 0],
            tong_so_diem_vocab: [0, 0, 0, 0, 0],
        }
    },
  computed: {
      chartAttendanceData(){
        return  {
          type: 'bar',
          title: {
            fontSize: 20,
            adjustLayout: true,
            flat: true
          },
          gui: {
            contextMenu: {
              empty: true
            }
          },
          globals: {
            fontFamily: "SVN-Gilroy"
          },
          plot: {
            tooltip: {
              "text": "%t: <b>%v</b> %data-percent",
              "padding": "10%",
              "border-radius": "5px",
              "sticky": true,
              "timeout": 5000
            },
            animation: {
              effect: "ANIMATION_FADE_IN",
              method: "ANIMATION_LINEAR",
              sequence: "ANIMATION_BY_PLOT_AND_NODE",
              speed: 275
            },
            'value-box': {
              //Displays all data values by default.
            }
          },
          scalableYaxis: { // need this object
            scalable: true,
            chartid: 'myChart', // could get chartId in a more dynamic way if needed
          },
          scaleX: {
            zooming: false,
            label: {
              text: 'Tuần',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            },
            maxItems: 52,
            labels: this.labelSaleX
          },
          scaleY: {
            zooming: false,
            maxItems: 10,

            label: {
              text: 'Thời gian(h)',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            },
            lineColor: '#e53935' // make scale line color match (not necessary)
          },
          plotarea: {
            margin: 'dynamic'
          },
          series: [
            {
              type: "bar",
              values: this.timeLearn,
              "background-color": "#584475",
              'thousands-separator': ".",
              text: "Giờ học",
              'data-percent': 'h'
            },
          ]
        }
      },
      chartVocabData(){
        return  {
          type: 'bar',
          title: {
            fontSize: 20,
            adjustLayout: true,
            flat: true
          },
          gui: {
            contextMenu: {
              empty: true
            }
          },
          globals: {
            fontFamily: "SVN-Gilroy"
          },
          plot: {
            tooltip: {
              "text": "%t: <b>%v</b> %data-percent",
              "padding": "10%",
              "border-radius": "5px",
              "sticky": true,
              "timeout": 5000
            },
            animation: {
              effect: "ANIMATION_FADE_IN",
              method: "ANIMATION_LINEAR",
              sequence: "ANIMATION_BY_PLOT_AND_NODE",
              speed: 275
            },
            stacked: true,
          },
          scalableYaxis: { // need this object
            scalable: true,
            chartid: 'myChart2', // could get chartId in a more dynamic way if needed
          },
          scaleX: {
            zooming: false,
            label: {
              text: 'Số buổi',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            },
            maxItems: 10,
            labels: this.labelSaleVocabX
          },
          scaleY: {
            zooming: false,
            maxItems: 10,
            label: {
              text: 'Số câu',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            }
          },
          // plotarea: {
          //   margin: 'dynamic'
          // },
          series: [
            {
              type: "bar",
              values: this.tong_so_diem_vocab,
              "background-color": "#433FF5",
              text: "Số từ vựng thu thập",
              'data-percent': 'từ vựng',
              stack: 1
            },
            {
              type: "bar",
              values: this.so_diem_vocab,
              "background-color": "#6FBAFF",
              text: "Số từ vựng đúng",
              'data-percent': 'từ vựng',
              stack: 1
            },
          ]
        }
      },
      chartGrammarData(){
        return  {
          type: 'bar',
          title: {
            fontSize: 20,
            adjustLayout: true,
            flat: true
          },
          gui: {
            contextMenu: {
              empty: true
            }
          },
          globals: {
            fontFamily: "SVN-Gilroy"
          },
          plot: {
            tooltip: {
              "text": "%t: <b>%v</b> %data-percent",
              "padding": "10%",
              "border-radius": "5px",
              "sticky": true,
              "timeout": 5000
            },
            animation: {
              effect: "ANIMATION_FADE_IN",
              method: "ANIMATION_LINEAR",
              sequence: "ANIMATION_BY_PLOT_AND_NODE",
              speed: 275
            },
            stacked: true,
          },
          scalableYaxis: { // need this object
            scalable: true,
            chartid: 'myChart1', // could get chartId in a more dynamic way if needed
          },
          scaleX: {
            zooming: false,
            label: {
              text: 'Số buổi',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            },
            maxItems: 10,
            labels: this.labelSaleGramaX
          },
          scaleY: {
            zooming: false,
            maxItems: 10,
            label: {
              text: 'Số câu',
              fontFamily: 'SVN-Gilroy',
              fontSize: 16,
            }
          },
          // plotarea: {
          //   margin: 'dynamic'
          // },
          series: [
            {
              type: "bar",
              values: this.tong_so_diem,
              "background-color": "#433FF5",
              text: "Tổng số điểm",
              'data-percent': 'điểm',
              stack: 1
            },
            {
              type: "bar",
              values: this.so_diem,
              "background-color": "#6FBAFF",
              text: "Số điểm đạt được",
              'data-percent': 'điểm',
              stack: 1
            },
          ]
        }
      },
  },
  watch: {
    idMember: {
      handler() {
        this.$store.dispatch(CHI_TIET_USER, this.idMember).then((res) => {
          this.form = res.data.data;
          this.itemImages = this.form.badges.map(item => {
            return this.publicPath + item.badge_image;
          })

        });
      },
      immediate: true
    },
    course_student_id: {
      handler() {
        this.$store.dispatch(STUDENT_RESULT_VOCAB, {course_student_id: this.course_student_id}).then((res) => {
          this.tong_tu = res.data.data.tong_tu;
          this.tu_vung_kem = res.data.data.tu_vung_kem;
          let so_diem = res.data.data.bieu_do.reduce((result,item) => {
            result.push(item.score);
            return result;
          }, []);
          this.so_diem_vocab = [...so_diem,...this.so_diem_vocab]
          let tong_so_diem = res.data.data.bieu_do.reduce((result,item) => {
            result.push(item.total_score);
            return result;
          }, []);
          this.tong_so_diem_vocab = [...tong_so_diem,...this.tong_so_diem_vocab];
          let labelX = res.data.data.bieu_do.reduce((result, item, key) => {
            result.push(++key);
            return result;
          }, []);
          this.labelSaleVocabX = [...labelX,...this.labelSaleVocabX];
        });
        this.$store.dispatch(STUDENT_RESULT_GRAMMAR, {course_student_id: this.course_student_id}).then((res) => {
          this.diem_trung_binh = res.data.data.diem_trung_binh;
          this.diem_giua_ki = res.data.data.diem_giua_ki;
          this.diem_cuoi_ki = res.data.data.diem_cuoi_ki;
          let so_diem = res.data.data.bieu_do.reduce((result,item) => {
            result.push(item.score);
            return result;
          }, []);
          this.so_diem = [...so_diem,...this.so_diem]
          let tong_so_diem = res.data.data.bieu_do.reduce((result,item) => {
            result.push(item.total_score);
            return result;
          }, []);
          this.tong_so_diem = [...tong_so_diem,...this.tong_so_diem];
          let labelX = res.data.data.bieu_do.reduce((result, item, key) => {
            result.push(++key);
            return result;
          }, []);
          this.labelSaleGramaX = [...labelX,...this.labelSaleGramaX];
        });
      },
      immediate: true
    },
    clan: {
      handler() {
        this.$store.dispatch(DETAIL_ATTENDANCE, {classroom_id: this.clan.classroom_id, user_clan_id : this.idMember}).then((res) => {
          this.total_attendance = res.data.data.total_attendance;
          this.total_homework = res.data.data.total_homework;
          let maxWeek = Object.keys(res.data.data.chart_speed_room).length;
          this.labelSaleX = Array(maxWeek).fill().map((x,i)=>i+1);
          this.timeLearn = Object.values(res.data.data.chart_speed_room);
          this.$refs.chart.reload();
        });
      },
      immediate: true
    },

  },
    methods: {
        close: function () {
            this.$emit('close');
        },
        closeDialog() {
          this.dialogAllBadges = false;
        },
        showAllBlades: function () {
          this.$store.dispatch(ALL_BADGES, this.form.code - 10000).then((res) => {
            this.dialog.images = res.data.data.map(item => {
              return this.publicPath + item.image;
            });
            this.allBadges = res.data.data.map(item => {
              return { url:this.publicPath + item.image, is_use: item.is_use };
            });
            this.dialog.title = 'Tất cả huy hiệu';
            this.dialogAllBadges = true;
          });
        },
    },
}
</script>
<style>
@media(max-width: 1400px) {
  .txt-custom{
    font-size: 12px;
  }
  .box-item-score .col-md-4{
    padding-left: 8px;
  }
}

</style>

