<template>
    <tr class="group-color-yet">
        <td></td>
        <td colspan="7"><b>Chưa có team</b></td>
    </tr>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
    components: {},
    name: 'row-yet',
    props: {

    },
    data() {
        return {}
    },
    methods: {},
}
</script>
