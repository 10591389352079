<template>
    <el-row>
        <el-row class="table-responsive-lg">
            <table class="table table-bordered m-0">
                <thead>
                <tr>
                    <th> Họ và tên </th>
                    <th class="text-center text-break"> Nhân vật</th>
                    <th class="text-center text-break"> Chọn thành viên để thêm</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in members" :key="key">
                    <td>{{item.name}}</td>
                    <td></td>
                    <td class="text-center">
                        <el-checkbox :label="{course_student_id : item.course_student_id, student_id : item.student_id}"
                                     class="checkbox-calendar"
                                     v-model="selectMember[key].selected"
                                     :checked="isChecked"
                        ></el-checkbox>
                    </td>
                </tr>
                </tbody>
            </table>
        </el-row>
        <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close" >Đóng</button>
            <button class="btn btn-primary ml-2" @click="submit">Thêm vào team</button>
        </span>
    </el-row>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {LIST_MEMBER_CLASS, ADD_MEMBER} from "@/core/services/store/user/teacher.module";

const _ = deepdash(lodash);

export default {
    components: {},
    name: 'add-member',
    props: {
        // id của team
        idTeam : {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            loading: false,
            selectMember: [],
            members: [],
            booleanValue: false,
            isChecked: false,
            classroom_id : '',
            userInClan: []
        }
    },

    created() {
      this.classroom_id = this.$route.params.id;
      this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id}).then((res) => {
        let currentClan = res.data.data.clan.find((item) => {
          return item.id === this.idTeam;
        })
        if (currentClan) this.userInClan = currentClan.user_clan
        if(res.data.data?.not_in_clan){
          this.members = res.data.data.not_in_clan;
          this.selectMember = this.members.map((item) => {
            return  { course_student_id : item.course_student_id, student_id : item.student_id, selected : false }
          });
        }
      }).catch((res) => {

      })
    },
    methods: {
        submit() {
          let memberAdd = this.selectMember.reduce((result,item) => {
            if (item.selected){
              result.push({course_student_id : item.course_student_id, student_id : item.student_id});
            }
            return result;
          }, []);
          if ([...this.userInClan, ...memberAdd].length > 6){
            this.$message({
              type: 'error',
              message: 'Giới hạn thành viên team là 6 thành viên. Vui lòng thử lại!',
              showClose: true
            });
            return
          }
          if (memberAdd.length === 0){
            this.$message({
              type: 'error',
              message: 'Bạn chưa chọn thành viên nào!',
              showClose: true
            });
          }else {
            this.$store.dispatch(ADD_MEMBER, {members : memberAdd, clan_id : this.idTeam, class_id: this.$route.params.id}).then(() => {
              this.close();
              this.$message({
                type: 'success',
                message: 'Thêm thành viên thành công!',
                showClose: true
              });
              this.$emit("reloadPage");
            }).catch((res) => {
              this.$message({
                type: 'success',
                message: 'Giới hạn thành viên team là 6 thành viên. Vui lòng thử lại!',
                showClose: true
              });
            })
            
            // .then( () => {
            //   this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id});
            // })
          }
        },
        close: function () {
            this.$emit('close');
        },
      },
}
</script>
