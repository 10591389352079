<template>
  <div id="el-container" >
    <div id="app" class="mx-auto overflow-auto h-50">
      <CoolLightBox
          :items="items"
          :index="index"
          :effect="'fade'"
          @close="index = null">
      </CoolLightBox>
      <div class="images-wrapper">
        <div
            class="image"
            v-for="(image, imageIndex) in itemImages"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image.url + ')', opacity: image.is_use=== 0 ? 0.3 : 1 }"
            :title="image.is_use === 1 ? 'Đã kích hoạt' : ''"
        >
          <i class="fas fa-unlock-alt" style="color: yellow; opacity: 0.8" v-show="image.is_use"></i>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end mt-3">
      <button class="btn btn-default mr-2" type="button" @click="close">
        <i v-if="loading" class="el-icon-loading"></i> Đóng
      </button>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  name: "all-badges",
  components: {
    CoolLightBox
  },
  props: {
    items: {
      type: Array,
      default(){
        return [];
      }
    },
    itemImages: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  data() {
    return {
      loading: false,
      index: null,
    }
  },
  methods : {
    close: function () {
      this.$emit('close');
    },
  }
}
</script>

<style>
#el-container .image{
  width: 80px;
  height: 80px;
  cursor: pointer;
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  padding-top: 0;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#el-container .images-wrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 5px;
}
#app {
  max-height: 400px;
}
.fa-unlock-alt {
  align-self: center;
  justify-self: center;
}
</style>
