<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <div class="row mb-3">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
                                <div class="dt-buttons">
                                    <router-link :to="{ name: 'classrooms' }"
                                                 v-slot="{ href, navigate }">
                                        <a :href="href" @click="navigate" class="btn btn-dark btn-sm mr-1" tabindex="0"
                                           aria-controls="dt-basic-example" type="button"><span><i
                                            class="fal fa-chevron-left mr-1"></i> Trở lại trang quản lý lớp học</span>
                                        </a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <el-row class="table-responsive-lg">
                          <el-empty v-if="isError" description="Không có dữ liệu"></el-empty>
                            <table class="table table-bordered m-0 responsive" v-else>
                                <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Team</th>
                                    <th class="text-center">Tên học viên</th>
                                    <th class="text-center">Chức danh</th>
                                    <th class="text-center">Nhân vật</th>
                                    <th class="text-center">HP</th>
                                    <th class="text-center">Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>
                                  <template  v-for="(clan, key) in clans">
                                    <row-group :key="key" :accept="true" @addMember="openAddMember" :clan="clan" v-if="clan.approve_status === 1" @reloadPage="reloadPage"></row-group>
                                    <row-group v-else @denied="openDeniedTeam" :clan="clan" :key="key" :accept="false" ></row-group>
                                    <template v-for="(member, k) in clan.user_clan">
                                      <row-member :key="member.id + k"
                                                  :member="member"
                                                  :accept="true"
                                                  :clan="clan"
                                                  :indexMember="++k"
                                                  @punish="openPunish"
                                                  @gift="openGift"
                                                  @detail="openDetailMember"
                                                  @reloadPage="reloadPage()"
                                      >
                                      </row-member>
                                    </template>
                                  </template>
                                    <row-yet ></row-yet>
                                    <row-member v-for="(member, index) in notInClans" :key="member.id + index" :accept="false" :member="member" :indexMember="++index"></row-member>
                                </tbody>
                            </table>
                        </el-row>
                        <div class="row mb-5"></div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Chi tiết học viên -->
        <el-dialog :title="dialog.title" class="responsive w-auto" :visible.sync="dialogDetailMember">
            <detail-member @close="closeDialog()"
                            v-if="dialogDetailMember"
                            :idMember="dialog.id"
                            :course_student_id="dialog.course_student_id"
                            :clan="dialog.clan"
            ></detail-member>
        </el-dialog>

        <!--  Từ chối -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogDenied">
            <denied @close="closeDialog()" @reloadPage="reloadPage()" v-if="dialogDenied" :idTeam="dialog.id"></denied>
        </el-dialog>

        <!-- Thêm thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogAddMember">
            <add-member @close="closeDialog()" @reloadPage="reloadPage()" v-if="dialogAddMember" :idTeam="dialog.id"></add-member>
        </el-dialog>

        <!-- Thưởng Thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogGiftSuccess">
            <gift @close="closeDialog()" :idMember="dialog.id" v-if="dialogGiftSuccess" :nameMember="dialog.name"></gift>
        </el-dialog>

        <!-- Phạt thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogPunish">
            <punish @close="closeDialog()" :idMember="dialog.id" v-if="dialogPunish" :nameMember="dialog.name"></punish>
        </el-dialog>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RowGroup from "@/views/pages/team/row-group";
import RowMember from "@/views/pages/team/row-member";
import RowYet from "@/views/pages/team/row-yet";
import Denied from "@/views/pages/team/denied";
import AddMember from "@/views/pages/team/add-member";
import Gift from "@/views/pages/team/gift";
import Punish from "@/views/pages/team/punish";
import DetailMember from "@/views/pages/team/detail-member";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);
import {mapGetters} from "vuex";
import {LIST_MEMBER_CLASS} from "@/core/services/store/user/teacher.module";

export default {
    components: {
        DetailMember,
        Punish,
        RowGroup,
        RowMember,
        RowYet,
        Denied,
        AddMember,
        Gift,
    },
    data() {
        return {
            dialogDenied: false,
            dialogAddMember: false,
            dialogGiftSuccess: false,
            dialogPunish: false,
            dialogDetailMember: false,
            dialog: {
                title: null,
                name: null,
            },
            classroom_id : '',
            clans: [],
            notInClans: [],
            isError: false,
        }
    },
    created() {
      this.classroom_id = this.$route.params.id;
      this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id}).then((res) => {
        this.clans = res.data.data.clan;
        this.notInClans = res.data.data.not_in_clan;
      }).catch(() => {
        this.isError = true;
      })
    },
    mounted() {
        let clas_name = localStorage.getItem("className");
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý lớp học"},
            {title: `Tổ chức lớp: ${clas_name}`, icon: 'far fa-flag'}
        ]);
    },
    computed: {
      ...mapGetters(['currentTitle'])
    },
    methods: {
        closeDialog() {
            this.dialogDetailMember = false;
            this.dialogDenied = false;
            this.dialogAddMember = false;
            this.dialogGiftSuccess = false;
            this.dialogPunish = false;
        },

        openDetailMember({ id, course_student_id, clan }) {
            this.dialog.title = 'Chi tiết học viên';
            this.dialog.id = id;
            this.dialog.course_student_id = course_student_id;
            this.dialog.clan = clan;
            this.dialogDetailMember = true;
        },
        openDeniedTeam({ id }) {
            this.dialog.title = 'Lý do từ chối'
            this.dialog.id = id;
            this.dialogDenied = true;
        },

        openAddMember({id, name}) {
            this.dialog.title = `Thêm thành viên vào : ${name}`
            this.dialog.id = id;
            this.dialogAddMember = true;
        },

        openGift({id, name}) {
            this.dialog.title = `Thưởng`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogGiftSuccess = true;
        },

        openPunish({id, name}) {
            this.dialog.title = `Phạt`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogPunish = true;
        },

        querySever(customProperties) {
        },

        mergeParams(customProperties) {
            let params = {
                page: this.paging.current_page,
                per_page: this.paging.per_page,
                view_as: this.form.view_as,
            };
            if (this.form.name) {
                params = _.merge(params, {name: this.form.name})
            }

            if (this.form.branch_id) {
                params = _.merge(params, {branch: this.form.branch_id})
            }

            if (this.form.course_id) {
                params = _.merge(params, {course: this.form.course_id})
            }

            if (this.form.status) {
                params = _.merge(params, {status: this.form.status})
            }

            if (this.form.startDate) {
                params = _.merge(params, {start_date: this.form.startDate})
            }

            if (this.form.endDate) {
                params = _.merge(params, {end_date: this.form.endDate})
            }
            params = _.merge(params, customProperties);
            return params;
        },
        reloadPage() {
            this.classroom_id = this.$route.params.id;
            this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id}).then((res) => {
                this.clans = res.data.data.clan;
                this.notInClans = res.data.data.not_in_clan;
            }).catch(() => {
                this.isError = true;
            })
        },
    },

}
</script>
